<template>
    <div class="privacy-policy" v-if="!isPhone">
        <!-- 左侧导航栏 -->
        <aside class="sidebar">
            <h3>KKPWA规则中心</h3>
            <hr />
            <ul>
                <li v-for="(item, index) in menuItems" :key="index" :class="{ 'current': componentName == item.name }"
                    @click.prevent="selectMenuItem(item.name)">
                    {{ item.text }}
                </li>
            </ul>
        </aside>
        <!-- 右侧内容区域 -->
        <div class="content">
            <component :is="currentComponent" />
        </div>
    </div>
    <!-- 手机端 -->
    <div v-else class="phone_box">
        <div v-if="isMask" class="mask" @click="onMask"></div>
        <div v-if="isMask" class="side" :class="{ 'visible': isVisible }">
            <ul>
                <li v-for="(item, index) in menuItems" :key="index" :class="{ 'current': componentName == item.name }"
                    @click.prevent="selectMenuItem(item.name)">
                    {{ item.text }}
                </li>
            </ul>
        </div>
        <div class="title">
            <div>KKPWA规则中心</div>
        </div>
        <div class="nav_box" :class="{ 'fixed': isFixed }" ref="target">
            <div @click="onClick" style="font-size: 20px;">☰</div>
            <div @click="scrollToTop">回到顶部</div>
        </div>
        <div class="text_box" :class="{ 'mt': isFixed }">
            <component :is="currentComponent" />
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed, shallowRef, nextTick } from 'vue';
import ComplianceRules from "./components/ComplianceRules.vue";
import PrivacyStatement from "./components/PrivacyStatement.vue";
import ProductRules from "./components/ProductRules.vue";
import PropertyRightsRules from "./components/PropertyRightsRules.vue";
import RestrictionRules from "./components/RestrictionRules.vue";
import ServiceAgreement from "./components/ServiceAgreement.vue";
import UsingStandards from "./components/UsingStandards.vue";
import ViolationSituation from "./components/ViolationSituation.vue";

const isMask = ref(false)
const isPhone = ref(false)
function setRemUnit() {
    isPhone.value = document.documentElement.clientWidth <= 768;
}
setRemUnit();
window.addEventListener('resize', setRemUnit);

const componentName = ref('PrivacyStatement')
const menuItems = ref([
    { text: "隐私声明", name: 'PrivacyStatement' },
    { text: "内容合规规则", name: 'ComplianceRules' },
    { text: "命名限制规则", name: 'RestrictionRules' },
    { text: "服务使用规范", name: 'UsingStandards' },
    { text: "投诉违规情况", name: 'ViolationSituation' },
    { text: "知识产权规则", name: 'PropertyRightsRules' },
    { text: "违禁产品规则", name: 'ProductRules' },
    { text: "服务协议", name: 'ServiceAgreement' },
])

// 使用 shallowRef 来存储组件，避免不必要的响应式
const currentComponent = shallowRef(PrivacyStatement);
const selectMenuItem = (value) => {
    componentName.value = value
    const componentMap = {
        ComplianceRules,
        PrivacyStatement,
        ProductRules,
        PropertyRightsRules,
        RestrictionRules,
        ServiceAgreement,
        UsingStandards,
        ViolationSituation
    };
    currentComponent.value = componentMap[value];
    window.scrollTo(0, 0);
    onMask()
}

const target = ref(null)
const isFixed = ref(false)
const targetOffsetTop = ref(0)
const handleScroll = debounce(() => {
    if (window.scrollY >= targetOffsetTop.value) {
        isFixed.value = true;
    } else {
        isFixed.value = false;
    }
})

function debounce(fn, delay) {
    let timer = null;
    return function (...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, args);
        }, delay);
    };
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}
const isVisible = ref(false)
const onClick = () => {
    isMask.value = true
    setTimeout(() => {
        isVisible.value = true
    }, 0);
}
const onMask = () => {
    isMask.value = false
    isVisible.value = false
}

onMounted(() => {
    if (!sessionStorage.getItem('hasRefreshed')) {
        sessionStorage.setItem('hasRefreshed', 'true');
        window.location.reload();
    }
    const type = window.location.hash.split('type=')[1] || 'PrivacyStatement';
    selectMenuItem(type)
    // const urlParams = new URLSearchParams(window.location.href);
    // const type = urlParams.get('type');
    if (isPhone.value) {
        targetOffsetTop.value = target.value.offsetTop
        window.addEventListener("scroll", handleScroll);
    }
})
onBeforeUnmount(() => {
    window.removeEventListener("scroll", handleScroll);
    sessionStorage.removeItem('hasRefreshed')
});
</script>

<style scoped lang="scss">
.privacy-policy {
    display: flex;
    font-family: Arial, sans-serif;
    min-height: 100vh;
}

.sidebar {
    position: fixed;
    width: 250px;
    height: 100vh;
    background: #f6F6F7;
    padding: 20px 34px;
    border-right: 1px solid #ddd;

    h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    ul {
        margin-top: 16px;
        list-style: none;
        padding: 0;

        li {
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            color: #3c3c43c7;
            cursor: pointer;
        }

        li:hover {
            color: #3451B2;
        }

        .current {
            color: #3451B2;
        }
    }
}

.content {
    flex: 1;
    padding: 20px 60px;
    background: #FFF;
    margin-left: 250px;
}

// 手机端
.phone_box {
    background: #FFF;

    .mask {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);
        z-index: 10;
    }

    .side {
        position: fixed;
        top: 0;
        width: 50%;
        height: 100vh;
        padding: 32px;
        background: #FFF;
        z-index: 11;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;

        &.visible {
            transform: translateX(0);
        }

        ul {
            margin-top: 16px;
            list-style: none;
            padding: 0;

            li {
                height: 36px;
                line-height: 36px;
                font-size: 14px;
                color: #3c3c43c7;
                cursor: pointer;
            }

            li:hover {
                color: #3451B2;
            }

            .current {
                color: #3451B2;
            }
        }
    }

    .title {
        display: flex;
        align-items: center;
        top: 0 !important;
        font-size: 16px;
        color: #3c3c43;
        font-weight: 600;
        height: 64px;
        border-bottom: 1px solid #ccc;
        padding: 0 24px;
    }

    .nav_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #3c3c43c7;
        background: #FFF;
        height: 48px;
        border-bottom: 1px solid #ccc;
        padding: 0 24px;
        transition: all 0.3s ease;

        div {
            height: 48px;
            display: flex;
            align-items: center;
        }
    }

    .fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .text_box {
        margin-top: 48px;
        padding: 0 24px;
    }

    .mt {
        margin-top: 96px;
    }
}
</style>
