<template>
    <div class="cape_advantage">
        <div class="cape_advantage_title">KK斗篷<span style="color: #48DAFF;">优势</span></div>
        <div v-fade-in-once class="cape_advantage_left fade-in-left">
            <div class="title_01">用<span style="color: #FFEC16;">3天时间</span>提高</div>
            <div class="title_02">你的广告投资回报率！</div>
            <div class="text_box">
                <div>快速把对您有害的访客拒之门外。比如审查</div>
                <div>IP、竞争对手、间谍爬虫，VPN代理，网络</div>
                <div>蜘蛛和您不想要的任何其他访问者。</div>
                <!-- 快速把对您有害的访客拒之门外。比如审查IP、竞争对手、间谍爬虫，VPN代理，网络蜘蛛和您不想要的任何其他访问者。 -->
            </div>
            <div class="image" v-lazy-bg="require('@/assets/img/fingerprint.png')">
                可自定义判断指纹
            </div>
            <div class="image" v-lazy-bg="require('@/assets/img/mechanism.png')">
                准确且高效的访客判断机制
            </div>
            <div class="image" v-lazy-bg="require('@/assets/img/website.png')">
                快速接入你自己的网站
            </div>
        </div>
        <div class="video-container">
            <div>
                <div v-if="!isPlaying" class="video-overlay">
                    <img src="@/assets/video/icon-video.png" class="icon-video" @click="playVideo">
                </div>
                <video v-if="!props.isPhone" ref="videoRef" :src="require('@/assets/video/dp_video.mp4')"
                    type="video/mp4" :controls="isPlaying" class="video_mp4" @ended="isPlaying = false">
                    您的浏览器不支持 HTML5 视频播放。
                </video>
                <img v-else src="@/assets/video/dpvideo_img.png" class="video_mp4" @click="playVideo" />
            </div>


        </div>
        <img src="@/assets/img/line_di.png" class="line_di" loading="lazy" />
        <img src="@/assets/img/line.png" class="line" loading="lazy" />
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
import { onMounted, reactive, ref, defineProps } from 'vue';
const props = defineProps({
    isPhone: {
        type: Boolean,
        default: false,
    },
})

const isPlaying = ref(false)
const videoRef = ref(null)
const playVideo = () => {
    if (props.isPhone) {
        document.body.style.transform = 'none';
        document.body.style.width = '100%';
        router.push({ path: '/demoVideo' })
    } else {
        if (!isPlaying.value) {
            videoRef.value.play()
            isPlaying.value = true
        }
    }

}
</script>

<style scoped lang="scss">
.cape_advantage {
    position: relative;
    width: 100%;
    margin-top: 5.5rem;
    margin-bottom: 11rem;
    height: 50rem;
    display: flex;
    justify-content: center;

    .cape_advantage_title {
        height: 4.125rem;
        line-height: 4.125rem;
        font-family: 'HYWenHei';
        font-size: 4.125rem;
        color: #FFFFFF;
    }

    .cape_advantage_left {
        position: absolute;
        left: 4rem;
        top: 8.5rem;
        z-index: 9;

        .title_01 {
            font-family: 'HYWenHei';
            font-size: 4rem;
            color: #FFFFFF;
            white-space: nowrap
        }

        .title_02 {
            font-family: 'HYWenHei';
            font-size: 3rem;
            color: #FFFFFF;
            white-space: nowrap
        }

        .text_box {
            margin-top: 2.0625rem;
            width: 28.5rem;
            font-family: 'AlibabaPuHuiTiR';
            font-size: 1.5rem;
            color: #9EB1DE;
            white-space: nowrap
        }

        .image {
            width: 32.4375rem;
            height: 5.5rem;
            line-height: 5.5rem;
            background: #28468B;
            border-radius: .9375rem;
            margin-top: 1.6rem;
            padding-left: 7.9375rem;
            font-family: 'AlibabaPuHuiTiR';
            font-size: 1.625rem;
            color: #FFFFFF;
            background-size: cover;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .cape_advantage_right {
        position: absolute;
        width: 39.625rem;
        right: 4rem;
        top: 8.5rem;
        z-index: 9;
    }

    .line_di {
        position: absolute;
        top: 8.875rem;
        height: 90%;
        z-index: 1;
    }

    .line {
        position: absolute;
        bottom: -3rem;
        width: 100%;
        z-index: 1;
    }
}

.video-container {
    position: relative;
    position: absolute;
    top: 10rem;
    right: 9.6875rem;
    z-index: 11;

    .video_mp4 {
        height: 37rem;

    }

    .video-overlay {
        position: relative;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.1);
        /* 半透明黑色蒙层 */
        opacity: 0.8;
        /* 初始隐藏蒙层 */
        transition: opacity 0.3s ease;
        /* 平滑过渡 */


        .icon-video {
            position: absolute;
            width: 5.2rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            transition: width 0.3s ease;
            z-index: 9;
        }
    }

    .video-overlay:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.3);


        .icon-video {
            width: 6rem;
        }
    }
}

/* 从左到右淡入 */
.fade-in-left {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-12.5rem);
    transition: opacity 2s ease, transform 2s ease;
}

.fade-in-left.visible {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}
</style>
