import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "../home/index.vue";
import PrivacyNotice from "../privacyNotice/index.vue";
import DemoVideo from "../video/index.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/kkpwaPrivacyNotice",
    name: "kkpwaPrivacyNotice",
    component: PrivacyNotice,
  },
  {
    path: "/demoVideo",
    name: "demoVideo",
    component: DemoVideo,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
