<template>
    <div class="domain_box">
        <div class="domain_title">PWA的<span style="color: #48DAFF;">技术优势</span></div>
        <div class="left_title">
            <img src="@/assets/img/jiantou.png" />
            <span style="color: #FFEA00;">领先技术</span>优势
        </div>
        <div class="right_title">
            产品<span style="color: #FFEA00;">演示视频</span>
            <img src="@/assets/img/jiantou.png" />
        </div>
        <div v-fade-in-once class="card fade-in">
            <div>
                <div class="image" v-lazy-bg="require('@/assets/img/custom_domain_name.png')">
                    <div class="title">自定义域名</div>
                    <div class="text">防止域名安装地址投放广告时被关联封禁</div>
                </div>
                <div class="image" v-lazy-bg="require('@/assets/img/two_way_verification.png')">
                    <div class="title">双向验证</div>
                    <div class="text">安装包和域名做双向验证 防劫持</div>
                </div>
            </div>
            <div>
                <div class="image" v-lazy-bg="require('@/assets/img/package_network.png')">
                    <div class="title">全包网支持</div>
                    <div class="text">支持所有包网配置简单</div>
                </div>
                <div class="image" v-lazy-bg="require('@/assets/img/unlimited_subdomains.png')">
                    <div class="title">无限子域名</div>
                    <div class="text">专属域名 自定义域名支持绑定无限子域名，便于推广</div>
                </div>
            </div>
        </div>
        <!-- <video controls :src="require('@/assets/video/kk_video.mp4')" type="video/mp4" class="video_mp4">
            您的浏览器不支持 HTML5 视频播放。
        </video> -->
        <div class="video-container">
            <div v-if="!isPlaying" class="video-overlay">
                <img src="@/assets/video/icon-video.png" class="icon-video" @click="playVideo">
            </div>
            <video ref="videoRef" :src="require('@/assets/video/kk_video.mp4')" type="video/mp4" :controls="isPlaying"
                class="video_mp4" @ended="isPlaying = false">
                您的浏览器不支持 HTML5 视频播放。
            </video>
        </div>
        <img src="@/assets/img/line_di.png" class="line_di" loading="lazy" />
        <img src="@/assets/img/line.png" class="line" loading="lazy" />
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, computed } from 'vue';

const isPlaying = ref(false)
const videoRef = ref(null)
const playVideo = () => {
    if (!isPlaying.value) {
        videoRef.value.play()
        isPlaying.value = true
    }
}
</script>

<style scoped lang="scss">
.domain_box {
    position: relative;
    width: 100%;
    height: 66rem;
    text-align: center;

    .domain_title {
        height: 4.125rem;
        line-height: 4.125rem;
        font-family: 'HYWenHei';
        font-size: 4.125rem;
        color: #FFFFFF;
    }

    .left_title {
        position: absolute;
        top: 7.875rem;
        left: 17rem;
        font-family: HYWenHei;
        font-size: 2.375rem;
        color: #FFFFFF;

        img {
            width: 1.125rem;
            height: 1.5625rem;
            margin-right: 1rem;
        }
    }

    .right_title {
        position: absolute;
        top: 7.875rem;
        right: 13rem;
        font-family: HYWenHei;
        font-size: 2.375rem;
        color: #FFFFFF;

        img {
            width: 1.125rem;
            height: 1.5625rem;
            margin-left: 1rem;
            transform: scaleX(-1);
        }
    }

    .card {
        display: flex;
        margin-left: 9.6875rem;
        padding-top: 9rem;
        position: relative;
        z-index: 9;

        .image {
            width: 15.5625rem;
            border-radius: 1.25rem;
            height: 19.5625rem;
            margin-right: 1.5rem;
            margin-bottom: 1.5rem;
            position: relative;
            display: flex;
            justify-content: center;
            // 背景图片公共样式
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            .title {
                position: absolute;
                top: 12.2rem;
                height: 2.1875rem;
                font-family: 'HYWenHei';
                font-weight: 600;
                font-size: 1.625rem;
                color: #FFFFFF;
            }

            .text {
                position: absolute;
                top: 14.5rem;
                opacity: 0.8;
                font-family: 'AlibabaPuHuiTiR';
                font-size: 1.125rem;
                color: #9BB3D8;
                padding: 0 1.5rem;
            }
        }
    }


    .video-container {
        position: relative;
        // width: 24rem;
        // height: 42rem;
        position: absolute;
        top: 13rem;
        right: 9.6875rem;
        z-index: 11;
        // border: .125rem solid #FFFFFF;

        .video_mp4 {
            height: 41rem;

        }

        .video-overlay {
            position: relative;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            background-color: rgba(0, 0, 0, 0.1);
            /* 半透明黑色蒙层 */
            opacity: 0.8;
            /* 初始隐藏蒙层 */
            transition: opacity 0.3s ease;
            /* 平滑过渡 */


            .icon-video {
                position: absolute;
                width: 5.2rem;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                transition: width 0.3s ease;
                z-index: 9;
            }
        }

        .video-overlay:hover {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.3);


            .icon-video {
                width: 6rem;
            }
        }
    }

    .line_di {
        position: absolute;
        height: 84%;
        top: 6.1rem;
        left: calc(50% - 65rem);
        z-index: 1;
    }

    .line {
        position: absolute;
        width: 100%;
        bottom: 6rem;
        left: 0%;
        width: 100%;
    }
}


/* 淡入动画 */
.fade-in {
    opacity: 0;
    visibility: hidden;
    /* 确保内容不可见但仍然渲染 */
    transform: translateY(12.5rem);
    transition: opacity 2s ease, transform 2s ease;
}

.fade-in.visible {
    opacity: 1;
    visibility: visible;
    /* 动画触发后让内容可见 */
    transform: translateY(0);
}
</style>
