<template>
    <div class="homePage">
        <!-- <a href="https://work.weixin.qq.com/ca/cawcde85f2ec507ae6">1111</a> -->
        <div class="tips" @click="clickLX">
            <img src="@/assets/img/tips.png" class="telegram" loading="lazy">
        </div>
        <div class="tips service" @click="clickLX">
            <img src="@/assets/img/customer service.png" class="telegram" loading="lazy">
        </div>

        <div v-if="isPhone" class="tips_video" @click="onPlayVideo">
            <img src="@/assets/img/video_play.png" class="telegram" loading="lazy">
        </div>
        <!-- 联系我们 弹窗模拟 -->
        <div>
            <div id="myModal" class="modal" :style="{ display: lxwm ? 'block' : 'none' }">
                <div :class="isPhone ? ['modal_content', 'modal_content_isPhone'] : 'modal_content'">
                    联系我们
                    <!-- <span id="close" @click="lxwm = false" :style="{fontSize:isPhone ? '5.54rem' : '1.5rem'}">x</span> -->
                    <img id="close" src="@/assets/img/shanchu.png" @click="lxwm = false"
                        :style="{ width: isPhone ? '3.5rem' : '1.25rem' }">
                    <div class="modal_text">
                        <div class="modal_text_top">
                            <div @click="onClick('Telegram')">
                                <img class="modal_l" src="@/assets/img/contactUs/fj.png" alt="">
                                <span>Telegram</span>
                                <span class="modal_r"></span>
                            </div>
                            <div @click="onClick('WhatsApp')">
                                <img class="modal_l" src="@/assets/img/contactUs/dh.png" alt="">
                                <span>WhatsApp</span>
                                <span class="modal_r"></span>
                                <!-- <img class="modal_r" v-else src="@/assets/img/contactUs/zj.png" alt=""> -->
                            </div>
                            <div v-if="isPhone" @click="onClick('WeChat')">
                                <img class="modal_l" src="@/assets/img/contactUs/wx.png" alt="">
                                <span>WeChat</span>
                                <span class="modal_r"></span>
                            </div>
                        </div>
                        <div class="modal_text_btm" :style="{ display: isPhone ? 'none' : 'block' }">
                            <div>
                                <img src="@/assets/img/contactUs/wx.png" alt=""> WeChat
                            </div>
                            <div class="modal_text_btm_ewm">
                                <img :src="wxImg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 顶部横幅 -->
        <div id="top_banner_isPhone" class="top_banner_isPhone" :style="{ display: isPhone ? 'block' : 'none' }">
            <div>
                <img src="@/assets/img/topBanner/icon_left.png" class="" loading="lazy">
                <span>
                    KKPWA 安装特惠，最低仅＄
                    <span class="top_banner_isPhone_money">
                        {{ money }}
                        <img class="top_banner_isPhone_money_img" src="@/assets/img/topBanner/xhx.png" loading="lazy">
                    </span>
                    !
                </span>
                <span>|</span>
                <span>一步到位，立省成本！</span>
                <!-- @click="lxwm = true" -->
                <span class="top_banner_isPhone_button" @click="clickLX">
                    立即联系
                </span>
            </div>
        </div>
        <div class="top_banner" :style="{ display: isBanner && !isPhone ? 'block' : 'none' }">
            <div class="top_banner_bgc">
                <img src="@/assets/img/topBanner/icon_left.png" class="top_banner_bgc_imgL" loading="lazy">
                <div>
                    <div class="top_banner_text">
                        KKPWA 安装特惠，最低仅＄
                        <span class="top_banner_text_l">
                            {{ money }}
                            <img class="top_banner_text_l_img" src="@/assets/img/topBanner/xhx.png" loading="lazy">
                            <span class="top_banner_text_l_t">!</span>
                        </span>
                        <span class="top_banner_text_m">|</span>
                        一步到位，立省成本！
                        <!-- lxwm = true -->
                        <div class="top_banner_text_r" @click="clickLX">立即联系</div>
                    </div>
                </div>
                <img src="@/assets/img/topBanner/icon_right.png" loading="lazy" class="top_banner_icon">
            </div>
            <img src="@/assets/img/topBanner/close.png" @click="bannerClick" class="top_banner_img" loading="lazy">
        </div>
        <!-- 数据模块 -->
        <div class="dataModular" :style="{ marginTop: isBanner && !isPhone ? '4rem' : '' }">
            <img src="@/assets/img/icon.png" class="logo" loading="lazy">
            <div class="image-container" ref="pauseArea">
                <div v-if="isMouseenter">
                    <img v-if="!currentShow" src="@/assets/img/title_o.png" class="title" loading="lazy">
                    <img v-else src="@/assets/img/title_t.png" class="title" loading="lazy">
                </div>
                <div v-else>
                    <img v-if="!show" v-fade-in-once src="@/assets/img/title_o.png" class="title image-reveal"
                        loading="lazy">
                    <img v-else v-fade-in-once src="@/assets/img/title_t.png" class="title image-reveal" loading="lazy">
                </div>
            </div>
            <img src="@/assets/img/experience.png" class="experience" @click="onExperience" loading="lazy">
            <div v-fade-in-once class="card_box fade-in">
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">100W+</div>
                    <div class="card_text">每月安装用户</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">{{ formattedInstallCount }}</div>
                    <div class="card_text">今日安装用户</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">3000+</div>
                    <div class="card_text">服务客户</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">20+</div>
                    <div class="card_text">涉及行业</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">65%</div>
                    <div class="card_text">安装率</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">2634</div>
                    <div class="card_text">活跃用户</div>
                </div>
            </div>
        </div>
        <div style="padding: 0 13.9375rem;">
            <!-- pwa技术 -->
            <pwaArea />
            <!-- 技术优势 -->
            <advanAreaNew v-if="!isPhone" />
            <advantageArea v-else />
            <!-- 核心功能 -->
            <coreArea />
            <!-- 斗篷超链的优势 -->
            <capeAdvantage :isPhone="isPhone" />
            <!-- 斗篷核心技术 -->
            <capeCore />
            <!-- 服务承诺 -->
            <serviceArea />
            <!-- 案例 -->
            <caseArea />
        </div>
        <!-- 页脚 -->
        <div class="floor">
            <div class="floor_box">
                <div class="one_box">
                    <img src="@/assets/img/icon.png" loading="lazy" class="floor_logo">
                    <p>© 2009, Hong Kong Global</p>
                    <div class="yuyan">
                        <img src="@/assets/img/yuyan.png" class="yuyan_img">
                        <span class="yuyan_text">中文（简体）</span>
                    </div>
                </div>
                <div class="two_box">
                    <div class="floor_box_title">文档和下载</div>
                    <div class="floor_box_text">WebPush API</div>
                    <div class="floor_box_text">广告轨道</div>
                    <div class="floor_box_text">ChromeOS 设备</div>
                </div>
                <div class="two_box">
                    <div class="floor_box_title">发现</div>
                    <div class="floor_box_text">机器学习</div>
                    <div class="floor_box_text" style="cursor: pointer;" @click="onPrivacy">隐私权政策</div>
                    <div class="floor_box_text">5G</div>
                </div>
                <div class="two_box">
                    <div class="floor_box_title">联系方式
                        <img src="@/assets/img/contact_us.png" class="contact_us" loading="lazy" @click="bottomClick">
                    </div>
                    <div class="floor_box_text">
                        <img src="@/assets/img/youxiang.png">
                        商业邮箱：888@kkpwa.com
                    </div>
                    <div class="floor_box_text robot" @click="onContact('https://t.me/KKPWA_kf_bot')">
                        <img src="@/assets/img/feiji.png">
                        Telegram机器人:@KKPWA_kf_bot
                    </div>
                    <div class="floor_box_text">
                        <img src="@/assets/img/gongsi.png">
                        公司名称：Hong Kong Global Communications Media Group Co., Limited
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, reactive, ref, computed, watch, defineComponent } from 'vue';
import pwaArea from './components/pwaArea.vue';
import advantageArea from './components/advantageArea.vue';
import advanAreaNew from './components/advanArea_new.vue';
import coreArea from './components/coreArea.vue';
import capeAdvantage from './components/capeAdvantage.vue';
import serviceArea from './components/serviceArea.vue';
import capeCore from './components/capeCore.vue';
import caseArea from './components/caseArea.vue';
import { useRouter } from 'vue-router'
const router = useRouter()
// const maxWidth = 1980; // 最大宽度限制
// const clientWidth = Math.min(document.documentElement.clientWidth, maxWidth); // 限制宽度
const isPhone = ref(false)
function setRemUnit() {
    const baseSize = 16; // 基准字体大小
    const designWidth = 1920; // 设计稿宽度
    const clientWidth = document.documentElement.clientWidth;
    const scale = clientWidth / designWidth; // 当前视口宽度与设计稿的比例
    const fontSize = baseSize * scale; // 根据视口计算的字体大小
    // 如果字体小于 10px，进行调整
    if (fontSize < 10) {
        // 设置 html 的 font-size 为 10px，避免强制放大
        document.documentElement.style.fontSize = '10px';
        document.documentElement.style.height = '100vh';
        // 使用 transform: scale() 进行缩放，适应小屏幕设备
        const scaleFactor = clientWidth / 1200; // 基于屏幕宽度来计算缩放比例
        document.body.style.transform = `scale(${scaleFactor})`; // 缩放页面内容
        document.body.style.transformOrigin = 'top left';
        document.body.style.width = '1200px';
        // document.body.style.transform = 'unset';
    } else {
        // 设置 html 的 font-size 来应用 rem 布局
        document.documentElement.style.fontSize = fontSize + 'px';
        // 清除 transform，恢复默认大小
        document.body.style.transform = 'none';
        document.body.style.width = '100%'; // 恢复默认宽度
    }

    // 判断是否为手机设备
    isPhone.value = clientWidth <= 900;
}
// 初始化
setRemUnit();
// 监听窗口变化
window.addEventListener('resize', setRemUnit);
// 联系我们
const onContact = (url) => {
    window.location.href = url
}
// 播放视频
const onPlayVideo = () => {
    window.location.href = "https://admin.kkpwa.com/demoVideo"
}
// 立即体验
const onExperience = () => {
    //  window.location.href = "https://admin.ys-test.top"
    window.location.href = "https://admin.kkpwa.com/"
}
const minInstallCount = ref(0); // 最小安装人数
const maxInstallCount = ref(0); // 最大安装人数
const startOfDay = ref(new Date()); // 当天的开始时间
const currentInstallCount = ref(0); // 当前人数
// 格式化显示安装人数
const formattedInstallCount = computed(() =>
    currentInstallCount.value.toLocaleString()
);
// 每次刷新页面时生成数据范围或从 localStorage 恢复
const initializeDailyData = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // 当天 0 点
    startOfDay.value = now;
    // 检查是否已有存储数据
    const storedData = JSON.parse(localStorage.getItem("installData"));
    // 如果是当天的数据，则恢复存储范围
    if (storedData && storedData.date === now.toISOString()) {
        minInstallCount.value = storedData.minInstallCount;
        maxInstallCount.value = storedData.maxInstallCount;
    } else {
        // 否则生成新数据范围
        generateDailyTarget();
        localStorage.setItem(
            "installData",
            JSON.stringify({
                date: now.toISOString(),
                minInstallCount: minInstallCount.value,
                maxInstallCount: maxInstallCount.value,
            })
        );
    }
};
// 随机生成当天的目标范围
const generateDailyTarget = () => {
    const min = 10000; // 最小值范围
    const max = 100000; // 最大值范围
    minInstallCount.value = Math.floor(Math.random() * (max - min + 1) + min);
    maxInstallCount.value = Math.floor(Math.random() * (max - min + 1) + min);
    if (minInstallCount.value > maxInstallCount.value) {
        [minInstallCount.value, maxInstallCount.value] = [
            maxInstallCount.value,
            minInstallCount.value,
        ];
    }
};
// 根据刷新时间动态计算安装人数
const calculateInstallCount = () => {
    const now = new Date();
    const elapsedTime = (now - startOfDay.value) / (24 * 60 * 60 * 1000); // 已过比例
    currentInstallCount.value = Math.floor(
        minInstallCount.value +
        elapsedTime * (maxInstallCount.value - minInstallCount.value)
    );
};
const pauseArea = ref(null)
const show = ref(false)
const currentShow = ref(false)
const isMouseenter = ref(false)
const pauseAnimation = () => {
    currentShow.value = JSON.parse(JSON.stringify(show.value))
    isMouseenter.value = true
}
const resumeAnimation = () => {
    isMouseenter.value = false
}
onMounted(async () => {
    initializeDailyData(); // 初始化数据范围
    calculateInstallCount(); // 计算当前人数
    setInterval(() => {
        show.value = !show.value
    }, 4000);
    // 添加事件监听器
    if (pauseArea.value) {
        pauseArea.value.addEventListener('mouseenter', pauseAnimation);
        pauseArea.value.addEventListener('mouseleave', resumeAnimation);
    }
    // 官网访问记录
    fetch('https://pwa-api.kkpwa.com/admin.Index/visit', { method: 'GET' }).then(res => { })
})
onBeforeUnmount(() => {
    if (pauseArea.value) {
        pauseArea.value.removeEventListener('mouseenter', pauseAnimation);
        pauseArea.value.removeEventListener('mouseleave', resumeAnimation);
    }
});

// 隐私权政策
const onPrivacy = () => {
    document.body.style.transform = 'none';
    document.body.style.width = '100%';
    router.push('/kkpwaPrivacyNotice?type=PrivacyStatement')
}

// 顶部横幅
const isBanner = ref(true)
const money = ref('0.016')
const bannerClick = () => {
    isBanner.value = false
}
// 获取按钮和模态框
const lxwm = ref(false)

const bottomClick = () => {
    if (isPhone.value) {
        const md = document.querySelector('.modal_content_isPhone')
        md.style.marginTop = '227%'
    }
    lxwm.value = true
}
watch(lxwm, (value) => {
    if (value) onClick('web')
}, {})
const clickLX = () => {
    if (isPhone.value) {
        const md = document.querySelector('.modal_content_isPhone')
        md.style.marginTop = '50%'
    }
    lxwm.value = true
}

const list = [
    { name: '南南', phone: '85256248303', img: require('@/assets/img/contactUs/nn.png'), code: 'KKPWA999' },
    { name: '慧玲', phone: '85268496892', img: require('@/assets/img/contactUs/hl.png'), code: 'KKPWA02' },
    { name: '小欧', phone: '85296030438', img: require('@/assets/img/contactUs/xo.png'), code: 'KKPWA88888' },
    { name: '凌晨', phone: '85264853049', img: require('@/assets/img/contactUs/lc.png'), code: 'KKPWA01' }
]
const wxImg = ref('')
const onClick = (value) => {
    let cachedData = getCache('userWeChat') // 读取缓存
    if (!cachedData) {
        const randomNumber = Math.floor(Math.random() * 4)
        cachedData = randomNumber
        setCache('userWeChat', randomNumber) // 设置缓存
    }

    wxImg.value = list[cachedData].img
    if (value === 'web') return

    if (value === 'Telegram') {
        window.open('https://t.me/KKPWA_kf_bot', '_blank')
    } else if (value === 'WhatsApp') {
        const phone = list[cachedData].phone
        const message = encodeURIComponent(
            'Thank you for joining KKPWA. Please contact our sales for more information about PWA'
        ) // 预设消息并进行 URL 编码
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${message}`
        window.open(whatsappUrl, '_blank')
    } else {
        //  微信 window.open('https://work.weixin.qq.com/ca/cawcde85f2ec507ae6')
        const wxCode = list[cachedData].code
        navigator.clipboard.writeText(wxCode).then(() => {
            alert('已复制微信号，请前往微信APP添加好友。')
            //   ElMessage({
            //     showClose: true,
            //     message: '已复制微信号，请前往微信APP添加好友。',
            //     type: 'success',
            //     duration: 5000
            //   })
        })
    }
}

// 存储缓存并设置过期时间（一天）
function setCache(key, value) {
    const now = new Date()
    const expirationTime = now.getTime() + 24 * 60 * 60 * 1000 // 24小时后过期

    const cacheData = {
        value: value,
        expiration: expirationTime
    }
    localStorage.setItem(key, JSON.stringify(cacheData))
}

// 获取缓存
function getCache(key) {
    const cacheData = localStorage.getItem(key)
    if (cacheData) {
        const parsedData = JSON.parse(cacheData)
        const now = new Date().getTime()
        // 如果缓存未过期，返回缓存的值
        if (now < parsedData.expiration) {
            return parsedData.value
        } else {
            // 如果缓存已过期，清除并返回 null
            localStorage.removeItem(key)
            return null
        }
    }
    return null // 没有缓存
}

</script>

<style scoped lang="scss">
@font-face {
    font-family: 'HYWenHei';
    src: url('@/assets/fonts/HYWenHei.ttf') format('truetype');
    font-display: swap;
    /* 使用系统字体临时替代 */
}

@font-face {
    font-family: 'AlibabaPuHuiTiB';
    src: url('@/assets/fonts/Alibaba-PuHuiTi-Bold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'AlibabaPuHuiTiR';
    src: url('@/assets/fonts/Alibaba-PuHuiTi-Regular.otf') format('opentype');
    font-display: swap;
}

@keyframes changeBackground {
    0% {
        background-image: url('@/assets/img/banner02.jpg');
        background-size: cover;
    }

    50% {
        background-image: url('@/assets/img/banner01.jpg');
        background-size: cover;
    }

    100% {
        background-image: url('@/assets/img/banner02.jpg');
        background-size: cover;
    }
}

/*顶部横幅 web*/
.top_banner {
    width: 100%;
    height: 4rem;
    box-sizing: border-box;
    background: #60E7FF;
    border-radius: 0px 0px 0px 0px;
    position: fixed;
    z-index: 999;
    color: #02021F;
    padding: 0 15%;
    font-size: 1.25rem;
    font-weight: 600;

    .top_banner_img {
        position: absolute;
        right: .625rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .top_banner_bgc {
        box-sizing: border-box;
        width: 100%;
        height: 4rem;
        line-height: 4rem;
        background-image: url('@/assets/img/topBanner/background.png');
        background-size: 69% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .top_banner_bgc_imgL {
            margin-right: 1.25rem;
            width: 2.5rem;
            height: 2.5rem;
        }

        .top_banner_text {
            box-sizing: border-box;
            height: 4rem;
            position: relative;

            .top_banner_text_l {
                position: absolute;
                margin-right: 3.125rem;
                font-size: 1.75rem;
                height: 4rem;
                font-weight: 900;

                .top_banner_text_l_img {
                    position: absolute;
                    top: 3rem;
                    left: 0;
                }

                .top_banner_text_l_t {
                    font-size: 1.25rem;
                    font-weight: 600;
                }
            }

            .top_banner_text_m {
                display: inline-block;
                width: 2.8rem;
                text-align: center;
                margin-left: 6.5rem;
            }

            .top_banner_text_r {
                display: inline-block;
                position: absolute;
                top: 50%;
                margin-top: -1.125rem;
                // box-sizing: border-box;
                font-size: .875rem;
                color: #FFFFFF;
                height: 2.25rem;
                line-height: 2.25rem;
                width: 6.5rem;
                background: #02021F;
                cursor: pointer;
                border-radius: 1.5rem;
                text-align: center;
                margin-left: .625rem;
            }
        }

        .top_banner_icon {
            max-height: 4rem;
            max-width: 4rem;
            margin-left: 8rem;
        }
    }
}

@media (max-width: 1600px) {
    .top_banner_text_m {
        margin-left: 8.5rem !important;
    }
}

@media (max-width: 1280px) {
    .top_banner_text_m {
        margin-left: 9.5rem !important;
    }
}

/*顶部横幅 isPhone*/
.top_banner_isPhone {
    // height: 16rem;
    font-weight: 900;
    height: 12rem;
    width: 100%;
    box-sizing: border-box;
    background: #60E7FF;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    // padding: 4.4rem 4rem;
    padding: 2.2rem;
    background-image: url('@/assets/img/topBanner/background.png');
    background-size: 100% 100%;

    div {
        width: 100%;
        height: 100%;

        line-height: 7.2rem;
        // line-height: 3.6rem;

        display: flex;
        justify-content: space-between;
        font-weight: 900;

        font-size: 2.66rem;
        // font-size: 1.7rem;

        .top_banner_isPhone_button {
            background: #02021F;
            color: #FFFFFF;
            text-align: center;

            width: 20.6rem;
            height: 7.2rem;
            line-height: 7.2rem;
            border-radius: 4.8rem;
            font-size: 2.5rem;

            // width: 10.3rem;
            // height: 3.6rem;
            // line-height: 3.6rem;
            // border-radius: 2.4rem;
            // font-size: 1.4rem;
        }

        .top_banner_isPhone_money {
            font-size: 4.2rem;
            // font-size: 2.2rem;

            display: inline-block;
            position: relative;

            .top_banner_isPhone_money_img {
                position: absolute;
                left: 0;

                top: 6.6rem;
                width: 17.2rem;
                height: 1.8rem;

                // top: 3.3rem;
                // width: 9.6rem;
                // height: 0.9rem;
            }
        }
    }
}

// 弹窗效果
.modal {
    display: none;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    // web
    .modal_content {
        box-sizing: border-box;
        margin: 15% auto;
        padding: 1.5rem;
        width: 30rem;
        background: #FFFFFF;
        border-radius: 1rem;
        font-family: SourceHanSansCN;
        font-weight: 500;
        font-size: 1.125rem;
        color: #333333;
        position: relative;

        #close {
            position: absolute;
            right: 1.875rem;
            font-size: 1.5rem;
            cursor: pointer;
            top: 1.7rem;
        }

        .modal_text {
            font-size: 1rem;

            .modal_text_top {
                margin-top: 2.1875rem;
                margin-bottom: 1.5rem;
                display: flex;
                justify-content: space-between;

                div {
                    box-sizing: border-box;
                    width: 12.5rem;
                    height: 3.5rem;
                    background: #FFFFFF;
                    border-radius: 1.75rem;
                    // border: 1px solid #1D74FF;
                    text-align: center;
                    line-height: 3.5rem;
                    position: relative;
                    display: flex;
                    // justify-content: space-around;
                    align-items: center;
                }

                div:hover {
                    background: rgba(29, 116, 255, 0.10);
                    cursor: pointer;
                    border: 1px solid #1D74FF;

                    .modal_r {
                        background-image: url('@/assets/img/contactUs/zj.png');
                        background-size: 100% 100%;
                    }
                }

                .modal_l {
                    width: 2rem;
                    height: 2rem;
                    margin-left: 1.5rem;
                    margin-right: .5rem;
                }

                .modal_r {
                    position: absolute;
                    right: 8.33%;
                    color: #1D74FF;
                    width: .8125rem;
                    height: 1.375rem;
                    background-image: url('@/assets/img/hui.png');
                    background-size: 100% 100%;
                }
            }

            .modal_text_btm {
                padding: 1.5rem;
                width: 27rem;
                height: 17.625rem;
                background: #FFFFFF;
                border-radius: 8px;

                // border: 1px solid #1D74FF;
                div:first-child {
                    display: flex;
                    align-items: center;
                    margin-bottom: .9375rem;

                    img {
                        width: 2rem;
                        height: 1.8125rem;
                        margin-right: .5rem;
                    }
                }

                // div:last-child{
                .modal_text_btm_ewm {
                    width: 24rem;
                    height: 11.875rem;
                    background: #F8F8F8;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 9.375rem;
                        height: 9.375rem;
                    }
                }

                // };
            }
        }
    }

    // isPhone
    .modal_content_isPhone {
        box-sizing: border-box;
        margin: 50% auto;
        padding: 5rem;
        padding-top: 9rem;
        width: 72%;
        height: 18%;
        font-size: 4rem;
        text-align: center;
        font-weight: 600;
        border-radius: 2rem;
        position: relative;

        #close {
            position: absolute;
            right: 5rem;
            top: 10.15rem;
            cursor: pointer;
        }

        .modal_text {
            height: 80%;

            .modal_text_top {
                height: 90%;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                font-size: 3.875rem;
                font-weight: 500;
                margin-top: 6.185rem;

                div {
                    width: 100% !important;
                    height: 13rem;
                    border-radius: 6.5rem;
                    background: #F8F8F8;

                    .modal_l {
                        width: 7rem;
                        height: 7rem;
                        margin-left: 4rem;
                        margin-right: 2rem;
                    }

                    .modal_r {
                        right: 5.33% !important;
                        width: 1.8125rem;
                        height: 3.375rem;
                        background-image: url('@/assets/img/hui.png');
                        background-size: 100% 100%;
                    }
                }

                div:hover {
                    .modal_r {
                        background-image: url('@/assets/img/contactUs/zj.png');
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}

.homePage {

    width: 100%;
    background: #18233D;

    .tips {
        position: relative;
        width: 8.8125rem;
        position: fixed;
        right: 3.75rem;
        top: 37rem;
        cursor: pointer;
        z-index: 99;

        .telegram {
            width: 8.8125rem;
        }

    }

    .service {
        top: 47rem;
    }

    .dataModular {
        position: relative;
        width: 100%;
        height: 49.1875rem;
        background-size: cover;
        animation: changeBackground 8s infinite;

        .logo {
            position: absolute;
            top: 1.9375rem;
            left: 15rem;
            height: 5.375rem;
        }

        /* 图片容器 */
        .image-container {
            position: absolute;
            top: 13.75rem;
            left: calc(50% - 27.125rem);

            .title {
                width: 54.25rem;
            }

        }

        .experience {
            position: absolute;
            top: 26.3125rem;
            left: calc(50% - 11.9063rem);
            width: 22rem;
            cursor: pointer;
        }

        .card_box {
            position: absolute;
            top: 36.0625rem;
            width: 100%;
            height: 12rem;
            text-align: center;

            .card {
                display: inline-block;
                margin: 0 .1875rem;
                padding-top: 2.5625rem;
                width: 13.875rem;
                height: 9.6875rem;
                border-radius: 1.875rem;
                border: .0625rem solid #41BAFF;
                background-size: cover;
                background-repeat: no-repeat;

                .card_title {
                    font-family: 'AlibabaPuHuiTiR';
                    font-size: 2.5rem;
                    color: #73ECFE;
                    height: 2rem;
                    line-height: 2rem;
                }

                .card_text {
                    font-family: 'AlibabaPuHuiTiR';
                    font-size: 1.1875rem;
                    color: #FFFFFF;
                    height: 1.5625rem;
                    line-height: 1.5625rem;
                    margin-top: 1.625rem;

                }
            }
        }
    }

    // 页脚
    .floor {
        position: relative;
        width: 100%;
        height: 20.5rem;
        background: #14171F;
        padding: 4.375rem 13.875rem;
        z-index: 9;


        .floor_box {
            display: flex;
            justify-content: space-between;

            .one_box {
                .floor_logo {
                    height: 5.375rem;
                    margin-bottom: 1.625rem;
                }

                p {
                    margin-bottom: 3.0625rem;
                    height: 1.0625rem;
                    line-height: 1.0625rem;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    font-size: 1.25rem;
                    color: #717171;
                }

                .yuyan {

                    height: 1.0625rem;
                    line-height: 1.0625rem;
                    font-family: HYWenHei;
                    font-weight: normal;
                    font-size: 1rem;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;

                    /* 子元素垂直居中 */
                    .yuyan_img {
                        width: 1.125rem;
                        margin-right: .5625rem;
                    }
                }
            }

            .two_box {
                margin-top: .375rem;

                .floor_box_title {
                    height: 1.1875rem;
                    line-height: 1.1875rem;
                    font-family: HYWenHei;
                    font-weight: normal;
                    font-size: 1.25rem;
                    color: #FFFFFF;
                    margin-bottom: 2.9375rem;
                    display: flex;
                    align-items: center;

                    .contact_us {
                        width: 9.5rem;
                        margin-left: 1rem;
                        cursor: pointer;
                    }
                }

                .floor_box_text {
                    height: 1.0625rem;
                    line-height: 1.0625rem;
                    font-family: HYWenHei;
                    font-weight: normal;
                    font-size: 1.125rem;
                    color: #999999;
                    margin-top: 2.0625rem;
                    display: flex;
                    align-items: center;

                    img {
                        width: 1.25rem;
                        margin-right: .5rem;
                    }
                }

                .robot:hover {
                    color: #00c250;
                    cursor: pointer;
                }
            }
        }
    }
}

/* 初始状态：从左到右遮罩 */
.image-reveal {
    clip-path: inset(0 100% 0 0);
    /* 隐藏图片，右侧完全遮挡 */
    transition: clip-path 1s ease;
}

.image-reveal.visible {
    clip-path: inset(0 0 0 0);
    /* 完全显示图片 */
}

@media (max-width: 1024px) {
    .tips {
        position: relative;
        width: 12rem !important;
        position: fixed;
        right: 3.75rem;
        top: 77rem !important;
        cursor: pointer;
        z-index: 99;

        .telegram {
            width: 12rem !important;
        }
    }

    .service {
        top: 97rem !important;
    }

    .tips_video {
        position: relative;
        width: 12rem !important;
        position: fixed;
        right: 3.75rem;
        top: 57rem !important;
        cursor: pointer;
        z-index: 99;

        .telegram {
            width: 12rem !important;
        }
    }
}

@media (max-width: 768px) {
    .top_banner_text_m {
        margin-left: 13rem !important;
    }

    .tips {
        position: relative;
        width: 15rem !important;
        position: fixed;
        right: 3.75rem;
        top: 37rem;
        cursor: pointer;
        z-index: 99;

        .telegram {
            width: 15rem !important;
        }
    }

    .tips_video {
        position: relative;
        width: 15rem !important;
        position: fixed;
        right: 3.75rem;
        top: 57rem !important;
        cursor: pointer;
        z-index: 99;

        .telegram {
            width: 15rem !important;
        }
    }
}
</style>
