import { createApp } from 'vue'
import App from './App.vue'
import './style/global.css'; // 引入全局样式
import fadeInOnce from "./directives/fadeInOnce";
import lazyBg from './directives/lazy-bg';
import router from './router'

const app = createApp(App);
app.use(router)
app.directive("fade-in-once", fadeInOnce);
app.directive('lazy-bg', lazyBg);  // 注册懒加载背景图片指令
app.mount("#app");
